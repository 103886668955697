
import MessageSnackbar from '@/components/MessageSnackbar.vue'
import NxaAlert from '@/components/NxaAlert.vue'
import { Auth } from '@/models/auth'
import { BackendStatus } from '@/models/backend-status'
import { initUser, setToken } from '@/services/Environment'
import { Component, Vue } from 'vue-property-decorator'
import { LoginResponse } from '@/models/LoginResponse'
import TfaDialog from '@/views/TfaDialog.vue'

@Component({
  components: {
    TfaDialog: TfaDialog,
    MessageSnackbar,
    NxaAlert
  }
}
)

export default class Login extends Vue {
  public loginResponse: LoginResponse = new LoginResponse()
  public credentials: Auth = new Auth()

  private isLoading = false
  public isPasswordResetting = false
  public isConnecting = true
  public isEnabled = false
  public btnText = 'Login'
  public error = ''
  public alert = false
  public status: BackendStatus = null
  private allowOtpInputOnly = false
  private isDialogVisible = false
  private dialogError: string = null

  public async created() {
    if (this.$router.currentRoute.query.reason === 'session_expired') {
      const errorMessage = this.$t('notification.sessionExpired').toString()
      this.showAlert(errorMessage)
    }

    this.btnText = this.$t('button.connecting').toString()
    this.credentials.otp = ''
    await this.connect()
  }

  private async connect() {
    this.isConnecting = true
    this.status = await this.$backend.get('/status', BackendStatus)
    if (this.status) {
      this.isEnabled = true
      this.btnText = this.$t('button.login').toString()
    } else {
      this.btnText = this.$t('button.backend_offline').toString()
      setTimeout(() => this.connect(), 1000)
    }
    this.isConnecting = false
  }

  public async login() {
    this.isLoading = true
    this.loginResponse = await this.$backend.post('/identity/login', this.credentials)
    // Invalid Credentials
    if (!this.loginResponse) {
      this.isLoading = false
      return
    }
    // Valid Credentials, TfaSecret set, prompt TOTP
    if (!this.loginResponse.token && this.loginResponse.requireTfa) {
      this.allowOtpInputOnly = true
      this.loginResponse = await this.$backend.post('/identity/login', this.credentials)
      // resets the Textfield and sends an error message, if totp was wrong
      if (!this.loginResponse.token) {
        if (this.credentials.otp !== '') Vue.prototype.messages.$emit('error', this.$t('error.wrong_otp'))
        this.credentials.otp = ''
      }
    }
    // Valid Credentials, no TfaSecret set
    if (this.loginResponse.token) {
      setToken(this.loginResponse.token)
      Vue.prototype.startSignalR(this.loginResponse.token)
      const user = await initUser()
      if (this.$route.query.redirect_to) {
        const redirect = this.$route.query.redirect_to as string
        await this.$router.push(redirect)
      } else {
        await this.$router.push({ name: 'servers.list', params: { orgSlug: user.organization.slug } })
      }
    }
    this.isLoading = false
  }

  private showAlert(message: string) {
    this.error = message
    this.alert = true
  }

  private forgotPassword() {
    if (this.credentials.email) {
      this.dialogError = null
      this.isDialogVisible = true
    } else {
      this.showAlert(this.$t('hint.enter_email').toString())
    }
  }

  private async sendPasswordReset() {
    this.isPasswordResetting = true
    try {
      await this.$http.post('/identity/forgot-password', { email: this.credentials.email })
      this.isDialogVisible = false
    } catch (ex) {
      this.dialogError = this.$t('error.internal').toString()
    }
    this.isPasswordResetting = false
  }
}
